// Chakra imports
import { Button, Flex, Input, useColorModeValue } from "@chakra-ui/react";
// Assets
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";

function Dropzone(props) {
  const { content, onFinishSelected,acceptedFormats, ...rest } = props;  
  const bg = useColorModeValue("gray.100", "navy.700");
  const borderColor = useColorModeValue("secondaryGray.100", "whiteAlpha.100");
  const [file, setFile] = useState("")
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (onFinishSelected && acceptedFiles.length > 0) {
        // Pass the first (and only) file to the callback
        onFinishSelected(acceptedFiles);

        // Log file path
        console.log("File Path:", acceptedFiles[0].path || "N/A");
        setFile(acceptedFiles[0].path || "N/A");
      }
    },
    maxFiles: 1,
    accept: acceptedFormats,
  });
  return (
    <Flex
      align='center'
      justify='center'
      bg={file.length>1?"green.300":bg}
      border='1px dashed'
      borderColor={borderColor}
      borderRadius='16px'
      w='100%'
      h='max-content'
      minH='100%'
      cursor='pointer'
      {...getRootProps({ className: "dropzone" })}
      {...rest}>
      <Input variant='main' {...getInputProps()} />
      <Button variant='no-effects'>{content}</Button>
    </Flex>
  );
}

export default Dropzone;
