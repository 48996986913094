import {
  Flex,
  Table,
  Checkbox,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import UserForm from "views/admin/form/userAdd";
import UserFormEdit from "views/admin/form/userAddEdit";
export default function CheckTable(props) {
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const [openFormAR, setOpenFormAR] = useState(false)
  const [openFormAREdit, setOpenFormAREdit] = useState(false)
  const [dataItem, setDataItem] = useState({})
  const handelOpenFormImageAR = () =>{
    setOpenFormAR(true)
  }
  const handleCloseForm = () =>{
    setOpenFormAR(false)
  }
  const handleCloseFormEdit = () =>{
    setOpenFormAREdit(false)
    setDataItem({})
  }
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 11;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  useEffect(() => {
    if(dataItem?.id){
     setOpenFormAREdit(true)
   }
    
  }, [dataItem])
  const handleSubmit = async (data,checked) => {
    
   
   
    const formDataToSend = new FormData();
   
    const storedData = localStorage.getItem('user');
    const dataUser = JSON.parse(storedData);
    formDataToSend.append("clubId", dataUser?.clubId);
    formDataToSend.append("adminId",  dataUser?.id);
    formDataToSend.append("fullName", data.fullName);
    formDataToSend.append("mail", data.mail);
    formDataToSend.append("passWord", data.password);
    formDataToSend.append("roleId", data.roleId);
    formDataToSend.append("id",  data?.id);
    formDataToSend.append("isRemove", checked);

    try {
      const response = await fetch(
        "https://clap.solutions-apps.com/ClubAdmin/EditRegisterAR",
        {
          method: "POST",
          body: formDataToSend,
        }
      );

      if (response.ok) {
        console.log("Form submitted successfully");
        // updateList();
       // Close the form after submission
      } else {
        console.error("Error submitting form:", response.statusText);
      }
    } catch (error) {
      console.error("Error submitting form:", error.message);
    }
  };
 
  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          User Table
        </Text>
        <Menu onFinishSelected={()=>{handelOpenFormImageAR()}}/>
      </Flex>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        {tableData.length>0?
        <Tbody {...getTableBodyProps()}>
      
        <>
          {page?.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}
               >
                {row?.cells?.map((cell, index) => {
                  let data = "";
                  if (cell?.column?.Header === "NAME") {
                    data = (
                      <Flex align='center'>
                        <Checkbox
                          onChange={(e) => {
                            console.log(e.target.checked);
                            handleSubmit(row?.original,e.target.checked)
                          }}
                          defaultChecked={row?.original?.isActive}
                          colorScheme='brandScheme'
                          me='10px'
                        />
                        <Text color={textColor} fontSize='sm' fontWeight='700' onClick={() => setDataItem(row?.original)}>
                          {cell.value}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "PROGRESS") {
                    data = (
                      <Flex align='center'>
                        <Text
                          me='10px'
                          color={textColor}
                          fontSize='sm'
                          fontWeight='700'>
                          {cell.value}%
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.Header === "EMAIL") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700' onClick={() => setDataItem(row?.original)}>
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "PASSWORD") {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700' onClick={() => setDataItem(row?.original)}>
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "ROLE") {
                    data = (
                      <>
                      {cell.value == 1 ?
                      <Text color={textColor} fontSize='sm' fontWeight='700' onClick={() => setDataItem(row?.original)}>
                        {"Admin"}
                      </Text>
                    :<Text color={textColor} fontSize='sm' fontWeight='700' onClick={() => setDataItem(row?.original)}>
                        {"Application"}
                      </Text>}
                      </>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor='transparent'>
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
          </>
         
        </Tbody>
        :null}
      </Table>
      {openFormAR &&<UserForm onClose={handleCloseForm}/>}
      {openFormAREdit &&<UserFormEdit data={dataItem} onClose={handleCloseFormEdit}/>}

    </Card>
  );
}
