/* eslint-disable */
import {
  Box,
  Checkbox,
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { IoEasel, IoLogoBuffer, IoMap, IoBrush  } from "react-icons/io5";

// Custom components
import Card from "components/card/Card";
import { AndroidLogo, AppleLogo, WindowsLogo } from "components/icons/Icons";
import Menu from "components/menu/MainMenu";
import React, { useEffect, useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import RoundedImage from "./RoundedImage";
import { useState } from "react";
import MyForm from "views/admin/form/ImageAR";
import MyFormEdit from "views/admin/form/ImageAREdit";

export default function DevelopmentTable(props) {
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 50;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("secondaryGray.500", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const [openFormAR, setOpenFormAR] = useState(false)
  const [openFormAREdit, setOpenFormAREdit] = useState(false)
  const [dataItem, setDataItem] = useState({})

  
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handelOpenFormImageAR = () =>{
    setOpenFormAR(true)
  }
  const handleCloseForm = () =>{
    setOpenFormAR(false)
  }
  const handleCloseFormEdit = () =>{
    setOpenFormAREdit(false)
    setDataItem({})

  }
   useEffect(() => {
     if(dataItem?.id){
      setOpenFormAREdit(true)
    }
     
   }, [dataItem])
  
  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
                 

      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Model Table
        </Text>
        <Menu onFinishSelected={()=>{handelOpenFormImageAR()}}/>

        
      </Flex>
      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color="gray.400"
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}
              >
                {row.cells.map((cell, index) => {
                  let data = "";
                  if (cell.column.Header === "NAME") {
                    data = (
                      <>
                      <Flex align="center" onClick={() => setDataItem(row?.original)}>
                         <IoBrush size={13}/>
                         <Text color={textColor} fontSize="sm" fontWeight="500" >
                        {cell.value}
                      </Text>
                      </Flex>
                      </>
                    
                    );
                  } else if (cell.column.Header === "TYPE") {
                    data = (
                      <Flex align="center">
                        {cell.value === "video" ? (
                          <IoEasel size={24} />
                        ) : cell.value === "map" ? (
                          <IoMap size={24} />
                        ) : cell.value === "object" ? (
                          <IoLogoBuffer size={24} />
                        ) : null}
                      </Flex>
                    );
                  } else if (cell.column.Header === "DATE") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {new Date(cell.value).toDateString()}
                      </Text>
                    );
                  } else if (cell.column.Header === "IMAGE") {
                    data = (
                      <Flex align="center">
                        <RoundedImage image={cell.value} />
                      </Flex>
                    );
                  }else if (cell.column.Header === "PASSWORD") {
                    data = (
                      <Flex align='center'>
                        <Checkbox
                          defaultChecked={cell.value}
                          colorScheme='brandScheme'
                          me='10px'
                          onChange={(e) => {
                            console.log(e.target.checked);
                          }}
                        />
                      </Flex>
                    );
                  }

                  
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor="transparent"
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {openFormAR&& <MyForm  onClose={handleCloseForm}/>}
      {openFormAREdit&& <MyFormEdit data={dataItem}  onClose={handleCloseFormEdit}/>}

    </Card>
  );
}
