import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import RtlLayout from 'layouts/rtl';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import { ApiProvider } from 'contexts/ApiContext';
import SignIn from 'views/auth/signIn';
import SignOut from 'views/auth/signIn/signout';
const loadDataFromStorage = async () => {
    try {
      // Use localStorage.getItem to retrieve data
      const storedData = localStorage.getItem('user');
	  console.log(storedData);

      if (storedData) {
        // Assume data is stored as a JSON string, parse it if necessary

        // Update state with the loaded data
        return true

        console.log('Data loaded successfully!');
      } else {
		return false
        console.log('No data found in storage.');
      }
    } catch (error) {
      console.error('Error loading data:', error);
    }
  };
ReactDOM.render(
	<ChakraProvider theme={theme}>
	<ApiProvider>
		<React.StrictMode>
			<ThemeEditorProvider>
				<HashRouter>
					<Switch>
					<Route path={`/admin`} component={AdminLayout} />
					<Route path={`/auth/sign-in/default`} component={SignIn} />
					<Route path={`/auth/sign-Out`} component={SignOut} />

					{await loadDataFromStorage() ?
					<>
						
						<Redirect from='/' to='/admin/data-tables' /> 
						</>: 
						<>
						
						<Redirect from='/' to='/auth/sign-in/default' />
						</>
						}
					
						
					</Switch>
				</HashRouter>
			</ThemeEditorProvider>
		</React.StrictMode>
		</ApiProvider>
	</ChakraProvider>,
	document.getElementById('root')
);
