/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Live Blatt - v1.1.0
=========================================================

* Product Page: https://www.solutions-apps.com/
* Copyright 2023 Live Blatt (https://www.solutions-apps.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, SimpleGrid } from "@chakra-ui/react";
import DevelopmentTable from "views/admin/dataTables/components/DevelopmentTable";
import CheckTable from "views/admin/dataTables/components/CheckTable";
import ColumnsTable from "views/admin/dataTables/components/ColumnsTable";
import ComplexTable from "views/admin/dataTables/components/ComplexTable";
import {
  columnsDataDevelopment,
  columnsDataCheck,
  columnsDataColumns,
  columnsDataComplex,
} from "views/admin/dataTables/variables/columnsData";
import tableDataDevelopment from "views/admin/dataTables/variables/tableDataDevelopment.json";
import tableDataCheck from "views/admin/dataTables/variables/tableDataCheck.json";
import tableDataColumns from "views/admin/dataTables/variables/tableDataColumns.json";
import tableDataComplex from "views/admin/dataTables/variables/tableDataComplex.json";
import React, { useEffect, useState } from "react";
import { useApiContext } from "contexts/ApiContext";
import MyForm from "../form/ImageAR";
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';

export default function Settings() {
  const {state, dispatch } = useApiContext();
  
  useEffect(() => {
    const storedData = localStorage.getItem('user');
    const dataUser = JSON.parse(storedData);
    const fetchData = async () => {
      try {
        const formData = new FormData();
        formData.append('clubId',dataUser?.clubId);
          formData.append('adminId', dataUser?.id);
          const response = await fetch('https://clap.solutions-apps.com/ClubAdmin/GetAllImageAR', {
            method: 'POST',
            body: formData,
          });

        if (!response.ok) {
          throw new Error('API request failed');
        }

        const data = await response.json();

        // Update the context with the API response
        dispatch({ type: 'UPDATE_API_DATA', payload: data?.data });

      } catch (error) {
        console.error('Error fetching API data:', error);
      }
      try {
        const formData = new FormData();
        formData.append('clubId',dataUser?.clubId);
        formData.append('adminId', dataUser?.id);
          const response = await fetch('https://clap.solutions-apps.com/ClubAdmin/GetAllUsersAR', {
            method: 'POST',
            body: formData,
          });

        if (!response.ok) {
          throw new Error('API request failed');
        }

        const data = await response.json();

        // Update the context with the API response
        dispatch({ type: 'UPDATE_API_USER', payload: data?.data });

      } catch (error) {
        console.error('Error fetching API data:', error);
      }
    };

    fetchData();
  }, [dispatch]);
  useEffect(() => {
    console.log(state);
  }, [state]);
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  useEffect(() => {
    const loadDataFromStorage = async () => {
      try {
        const storedData = localStorage.getItem('user');
        const dataUser = JSON.parse(storedData);
        console.log(dataUser);
        if (storedData) {
          setIsAuthenticated(true);
          console.log('User is authenticated.');
        } else {
          setIsAuthenticated(false);
          console.log('User is not authenticated.');
        }
      } catch (error) {
        console.error('Error loading data:', error);
      }
    };

    loadDataFromStorage();
  }, []);
  if (!isAuthenticated) {
    return <Redirect to="/auth/sign-in/default" />;
  }
  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 2 }}
        spacing={{ base: "20px", xl: "20px" }}>
        {state?.apiData?.dataContent ?
        <DevelopmentTable
          columnsData={ [
          
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "TYPE",
    accessor: "type",
  },
  {
    Header: "DATE",
    accessor: "insertTime",
  },
  {
    Header: "IMAGE",
    accessor: "image",
  },
]}
          tableData={state?.apiData?.dataContent}
        />
        :null}
        
        <CheckTable columnsData={ [
  {
    Header: "NAME",
    accessor: "fullName",
  },
  {
    Header: "EMAIL",
    accessor: "mail",
  },
  {
  Header: "PASSWORD",
    accessor: "password",
  },
  {
    Header: "ROLE",
    accessor: "roleId",
  },
]} tableData={state?.apiDataUser?.dataContent.length>0?state?.apiDataUser?.dataContent:[]} />
        {/* <ColumnsTable
          columnsData={columnsDataColumns}
          tableData={tableDataColumns}
        />
        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        /> */}
     
      </SimpleGrid>
     
    </Box>
  );
}
