import React, { useEffect, useContext } from 'react';

// Create a context for your API data
const ApiContext = React.createContext();

// Define a reducer function to update the context
const apiReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_API_DATA':
      return {
        ...state,
        apiData: action.payload,
      };
      case 'UPDATE_API_USER':
      return {
        ...state,
        apiDataUser: action.payload,
      };
      case 'UPDATE_API_USER_PROFILE':
        return {
          ...state,
          apiDataUserProfile: action.payload,
        };
    default:
      return state;
  }
};

// Create a provider component using the context and reducer
export const ApiProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(apiReducer, {
    apiData: {dataContent:[]},
    apiDataUser:  {dataContent:[]},
  });

  return (
    <ApiContext.Provider value={{ state, dispatch }}>
      {children}
    </ApiContext.Provider>
  );
};

// Hook to access the context values
export const useApiContext = () => {
  return useContext(ApiContext);
};

// Component to make the API call and update the context


