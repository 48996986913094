/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Live Blatt - v1.1.0
=========================================================

* Product Page: https://www.solutions-apps.com/
* Copyright 2023 Live Blatt (https://www.solutions-apps.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Box, Grid } from "@chakra-ui/react";

// Custom components
import Banner from "views/admin/profile/components/Banner";
import General from "views/admin/profile/components/General";
import Notifications from "views/admin/profile/components/Notifications";
import Projects from "views/admin/profile/components/Projects";
import Storage from "views/admin/profile/components/Storage";
import Upload from "views/admin/profile/components/Upload";

// Assets
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar4.png";
import React, { useEffect } from "react";
import { useApiContext } from "contexts/ApiContext";

export default function Overview() {

  const {state, dispatch } = useApiContext();
  useEffect(() => {
    const storedData = localStorage.getItem('user');
    const dataUser = JSON.parse(storedData);
    const fetchData = async () => {

      try {
        const formData = new FormData();
        formData.append('clubId',dataUser?.clubId);
          formData.append('adminId', dataUser?.id);
          const response = await fetch('https://clap.solutions-apps.com/ClubAdmin/GetAllImageAR', {
            method: 'POST',
            body: formData,
          });

        if (!response.ok) {
          throw new Error('API request failed');
        }

        const data = await response.json();

        // Update the context with the API response
        dispatch({ type: 'UPDATE_API_DATA', payload: data?.data });

      } catch (error) {
        console.error('Error fetching API data:', error);
      }
      try {
        const formData = new FormData();
        formData.append('clubId',dataUser?.clubId);
        formData.append('adminId', dataUser?.id);
          const response = await fetch('https://clap.solutions-apps.com/ClubAdmin/GetAllUsersAR', {
            method: 'POST',
            body: formData,
          });

        if (!response.ok) {
          throw new Error('API request failed');
        }

        const data = await response.json();

        // Update the context with the API response
        dispatch({ type: 'UPDATE_API_USER', payload: data?.data });

      } catch (error) {
        console.error('Error fetching API data:', error);
      }
      try {
        const formData = new FormData();
        formData.append('clubId',dataUser?.clubId);
          formData.append('adminId', dataUser?.id);
          const response = await fetch('https://clap.solutions-apps.com/ClubAdmin/GetAllUsersARProfile', {
            method: 'POST',
            body: formData,
          });

        if (!response.ok) {
          throw new Error('API request failed');
        }

        const data = await response.json();

        // Update the context with the API response
        dispatch({ type: 'UPDATE_API_USER_PROFILE', payload: data?.data });

      } catch (error) {
        console.error('Error fetching API data:', error);
      }
    };

    fetchData();
  }, [dispatch]);
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Grid
        templateColumns={{
          base: "1fr",
          lg: "1.34fr 1fr 1.62fr",
        }}
        templateRows={{
          base: "repeat(3, 1fr)",
          lg: "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>
        <Banner
          gridArea='1 / 1 / 2 / 2'
          banner={banner}
          avatar={state?.apiDataUserProfile?.dataContent?.length>0 ? state?.apiDataUserProfile?.dataContent[0]?.avatarUrl : ''}
          name={state?.apiDataUserProfile?.dataContent?.length>0 ? state?.apiDataUserProfile?.dataContent[0]?.fullName : ''}
          job={state?.apiDataUserProfile?.dataContent?.length>0 ? state?.apiDataUserProfile?.dataContent[0]?.mail : ''}
          posts={state?.apiData?.dataContent?.length}
          followers={state?.apiData?.apiDataUser}
          following={state?.apiDataUser?.dataContent?.length}
        />
        <Storage
          gridArea={{ base: "2 / 1 / 3 / 2", lg: "1 / 2 / 2 / 3" }}
          used={25.6}
          total={50}
        />
        <Upload
          gridArea={{
            base: "3 / 1 / 4 / 2",
            lg: "1 / 3 / 2 / 4",
          }}
          minH={{ base: "auto", lg: "420px", "2xl": "365px" }}
          pe='20px'
          pb={{ base: "100px", lg: "20px" }}
        />
      </Grid>
      <Grid
        mb='20px'
        templateColumns={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1.34fr 1.62fr 1fr",
        }}
        templateRows={{
          base: "1fr",
          lg: "repeat(2, 1fr)",
          "2xl": "1fr",
        }}
        gap={{ base: "20px", xl: "20px" }}>
        <Projects
       
          
       
          name='Adela Parkson'
          job='Product Designer'
          posts='17'
          followers='9.7k'
          following='274'
        />
        
       
      </Grid>
    </Box>
  );
}
