import { AttachmentIcon, CloseIcon } from "@chakra-ui/icons";
import {
  ChakraProvider,
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  InputGroup,
  Select,
  Text,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Checkbox,
  Flex,
  Tooltip,
  IconButton,
  useColorModeValue,
  Icon,
  Textarea,
  InputLeftAddon,
  InputRightAddon,
  Spinner,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useApiContext } from "contexts/ApiContext";
import { useState } from "react";
import { MdUpload } from "react-icons/md";
import Dropzone from "views/admin/profile/components/Dropzone";

function UserForm({ onClose }) {
  const [formType, setFormType] = useState("Video");
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    fullName: null,
    mail: null,
    passWord: null,
    roleId: "",
  
   
  });
  const {state, dispatch } = useApiContext();

  const updateList = async () => {
    const storedData = localStorage.getItem('user');
    const dataUser = JSON.parse(storedData);
    const fetchData = async () => {
      try {
        const formData = new FormData();
        formData.append('clubId',dataUser?.clubId);
          formData.append('adminId', dataUser?.id);
          const response = await fetch('https://clap.solutions-apps.com/ClubAdmin/GetAllUsersAR', {
            method: 'POST',
            body: formData,
          });

        if (!response.ok) {
          throw new Error('API request failed');
        }

        const data = await response.json();

        // Update the context with the API response
        dispatch({ type: 'UPDATE_API_USER', payload: data?.data });

      } catch (error) {
        console.error('Error fetching API data:', error);
      }
      
    };

    fetchData();
  }

  const handleSubmit = async (event) => {
    setIsLoading(true)

    event.preventDefault();
    console.log("Form Data:", formData);
   
    const formDataToSend = new FormData();
    formDataToSend.append("fullName", formData.fullName);
    formDataToSend.append("mail", formData.mail);
    formDataToSend.append("passWord", formData.passWord);
    formDataToSend.append("roleId", formData.roleId);
    const storedData = localStorage.getItem('user');
    const dataUser = JSON.parse(storedData);
    formDataToSend.append("clubId", dataUser?.clubId);
    formDataToSend.append("adminId",  dataUser?.id);
    

    try {
      const response = await fetch(
        "https://clap.solutions-apps.com/ClubAdmin/AddRegisterAR",
        {
          method: "POST",
          body: formDataToSend,
        }
      );

      if (response.ok) {
        console.log("Form submitted successfully");
        updateList();
        onClose(); // Close the form after submission
      
      } else {
        console.error("Error submitting form:", response.statusText);
      }
    } catch (error) {
      console.error("Error submitting form:", error.message);
    }
    setIsLoading(false)

  };

  const handleTypeChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      roleId: event.target.value,
    }));
    setFormType(event.target.value);
  };

 

  const handleChange = (event) => {
    const { name, value, type, files } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "file" ? files[0] : value,
    }));
  };
 

  const handleRemoveFile = (fileType) => {
    setFormData((prevData) => ({
      ...prevData,
      [fileType]: null,
    }));
  };
  const brandColor =  useColorModeValue("brand", "brand.300");
  return (
    <Modal isOpen onClose={onClose} size="xl" >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontSize="2xl" fontWeight="bold">
          CREATE YOUR MODEL
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel htmlFor="name">Project Name</FormLabel>
              <Input
                type="text"
                id="fullName"
                name="fullName"
                placeholder="John Doe"
                _placeholder={{ color: "gray.500" }}
                onChange={handleChange}
                required
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel htmlFor="roleId">Select ROle</FormLabel>
              <Select
                id="roleId"
                name="roleId"
                onChange={handleTypeChange}
                value={formType}
                placeholder="Select type"
                _placeholder={{ color: "gray.500" }}
                isRequired
              >
                <option value="1">Admin Panel</option>
                <option value="2">Application</option>
                
              </Select>
            </FormControl>
            <FormControl>
                  <FormLabel>Email</FormLabel>
                  <Input
                    type="email"
                    name="mail"
                    required
                    value={formData.mail}
                    onChange={handleChange}
                  />
                </FormControl>
            <FormControl>
                  <FormLabel>Password</FormLabel>
                  <Input
                    type="text"
                    name="passWord"
                    required
                    value={formData.passWord}
                    onChange={handleChange}
                  />
                </FormControl>
            
           

                <Button
  mt={6}
  
  type="submit"
  colorScheme={brandColor}
  style={{ backgroundColor: brandColor, width: 100, position: "relative" }}
  _hover={{ bg: brandColor }}
  _active={{ bg: brandColor }}
  disabled={isLoading}
  onClick={handleSubmit} // Assuming you have an onClick handler for the button
  
>
  {isLoading ? (
    <Spinner
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
      color="teal.500"
      size="sm"
    />
  ) : (
    "Submit"
  )}
</Button>
          </form>
        </ModalBody>
        <ModalFooter>
          {/* Additional content or buttons can be added here */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
export default UserForm;
