/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Live Blatt UI - v1.1.0
=========================================================

* Product Page: https://www.Live Blatt-ui.com/
* Copyright 2023 Live Blatt (https://www.Live Blatt-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';

function SignOut() {
  // Chakra color mode
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  useEffect(() => {
    localStorage.clear()
    setIsAuthenticated(false);
  }, [])
  

 
  if (!isAuthenticated) {
    return <Redirect to="/auth/sign-in/default" />;
  }
  return (
    <>
     
    </>
  );
}

export default SignOut;
