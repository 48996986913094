import { AttachmentIcon, CloseIcon } from "@chakra-ui/icons";
import {
  ChakraProvider,
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  InputGroup,
  Select,
  Text,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Checkbox,
  Flex,
  Tooltip,
  IconButton,
  useColorModeValue,
  Icon,
  Textarea,
  InputLeftAddon,
  InputRightAddon,
  Spinner,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useApiContext } from "contexts/ApiContext";
import { useEffect, useState } from "react";
import { MdUpload } from "react-icons/md";
import Dropzone from "views/admin/profile/components/Dropzone";

function MyFormEdit({ onClose ,data}) {
  const [formType, setFormType] = useState("Video");
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    file: null,
    video: null,
    objectModel: null,
    name: "",
    mapLa: "",
    ytb: "",
    mapLo: "",
    IsPassword: false,
    type: "",
    avatar: null,
    fullName: "",
    addressSite: "",
    instagram: "",
    facebook: "",
    twitter: "",
    email: "",
    phone: "",
    description: "",
    latitude: "",
    longitude: "",
  });
  useEffect(() => {
    setFormData((prevData) => ({
        ...prevData,
        ...data,
      }));
      setFormType(data?.type);
      setFormData((prevData) => ({
        ...prevData,
        file:null,
      }));
      setFormData((prevData) => ({
        ...prevData,
        video:null,
      }));
      setFormData((prevData) => ({
        ...prevData,
        objectModel:null,
      }));
      setFormData((prevData) => ({
        ...prevData,
        avatar:null,
      }));
  }, [data])
  useEffect(() => {
    console.log("handleSubmit",formData);
    
  }, [formData])
  const {state, dispatch } = useApiContext();

  const updateList = async () => {
    const storedData = localStorage.getItem('user');
    const dataUser = JSON.parse(storedData);
    const fetchData = async () => {
      try {
        const formData = new FormData();
        formData.append('clubId',dataUser?.clubId);
          formData.append('adminId', dataUser?.id);
          const response = await fetch('https://clap.solutions-apps.com/ClubAdmin/GetAllImageAR', {
            method: 'POST',
            body: formData,
          });

        if (!response.ok) {
          throw new Error('API request failed');
        }

        const data = await response.json();

        // Update the context with the API response
        dispatch({ type: 'UPDATE_API_DATA', payload: data?.data });

      } catch (error) {
        console.error('Error fetching API data:', error);
      }
      
    };

    fetchData();
  }

  const handleSubmit = async (event) => {
    setIsLoading(true)

    event.preventDefault();
    console.log("Form Data:", formData);
    

    const formDataToSend = new FormData();
    formDataToSend.append("id", data?.id);

    formDataToSend.append("name", formData.name);
    formDataToSend.append("type", formData.type);
        formDataToSend.append("ytb", formData.ytb);

    formDataToSend.append("longitude", formData.longitude);
    formDataToSend.append("latitude", formData.latitude);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("phone", formData.phone);
    formDataToSend.append("email", formData.email);
    formDataToSend.append("twitter", formData.twitter);
    formDataToSend.append("facebook", formData.facebook);
    formDataToSend.append("fullName", formData.fullName);
    formDataToSend.append("addressSite", formData.addressSite);
    formDataToSend.append("avatar", formData.avatar);
    const storedData = localStorage.getItem('user');
    const dataUser = JSON.parse(storedData);
    formDataToSend.append("clubId", dataUser?.clubId);
    formDataToSend.append("adminId",  dataUser?.id);
    if (formData.video) {
      formDataToSend.append("video", formData.video);
    }

    if (formData.object) {
      formDataToSend.append("object", formData.object);
    }

    if (formData.file) {
      formDataToSend.append("file", formData.file);
    }

    if (formData.mapLa && formData.mapLo) {
      formDataToSend.append("mapLa", formData.mapLa);
      formDataToSend.append("mapLo", formData.mapLo);
    }

    formDataToSend.append("iPassword", formData.iPassword);

    try {
      const response = await fetch(
        "https://clap.solutions-apps.com/ClubAdmin/EditImageAR",
        {
          method: "POST",
          body: formDataToSend,
        }
      );

      if (response.ok) {
        console.log("Form submitted successfully");
        setIsLoading(false)

        updateList();
        onClose(); // Close the form after submission
      } else {
        console.error("Error submitting form:", response.statusText);
        setIsLoading(false)

      }
    } catch (error) {
      console.error("Error submitting form:", error.message);
      setIsLoading(false)

    }
    setIsLoading(false)

  };

  const handleTypeChange = (event) => {
    setFormData((prevData) => ({
      ...prevData,
      type: event.target.value,
    }));
    setFormType(event.target.value);
  };

  const handleAgreeTermsChange = () => {
    setFormData((prevData) => ({
      ...prevData,
      iPassword: !prevData.agreeTerms,
    }));
  };

  const handleChange = (event) => {
    const { name, value, type, files } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "file" ? files[0] : value,
    }));
  };
  const handleFileChange = (event, fileType) => {
    const file = event.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      [fileType]: file,
    }));
  };
  const handleFinishSelectedVideo = (files) => {
    // Do something with the selected files
    setFormData((prevData) => ({
      ...prevData,
      video: files[0],
    }));
    console.log("Selected Files:", files);
  };
  const handleFinishSelectedImage = (files) => {
    // Do something with the selected files
    setFormData((prevData) => ({
      ...prevData,
      file: files[0],
    }));
    console.log("Selected Files:", files);
  };
  const handleFinishSelectedImageAvatar = (files) => {
    // Do something with the selected files
    setFormData((prevData) => ({
      ...prevData,
      avatar: files[0],
    }));
    console.log("Selected Files:", files);
  };
  const handleFinishSelectedObject = (files) => {
    // Do something with the selected files
    setFormData((prevData) => ({
      ...prevData,
      objectModel: files[0],
    }));
    console.log("Selected Files:", files);
  };

  const handleRemoveFile = (fileType) => {
    setFormData((prevData) => ({
      ...prevData,
      [fileType]: null,
    }));
  };
  const brandColor =  useColorModeValue("brand", "brand.300");
  return (
    <Modal isOpen onClose={onClose} size="xl" >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontSize="2xl" fontWeight="bold">
          EDIT YOUR MODEL
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel htmlFor="name">Project Name</FormLabel>
              <Input
                type="text"
                id="name"
                name="name"
                placeholder="John Doe"
                _placeholder={{ color: "gray.500" }}
                onChange={handleChange}
                value={formData.name}
                required
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel htmlFor="type">Select Type</FormLabel>
              <Select
                id="type"
                name="type"
                onChange={handleTypeChange}
                value={formData.type}
                
                placeholder="Select type"
                _placeholder={{ color: "gray.500" }}
              >
                <option value="video">Video</option>
                <option value="object">Object</option>
                <option value="map">Map</option>
                <option value="card">Card</option>
              </Select>
            </FormControl>
            <InputGroup mt={4}>
              <FormControl>
                <Dropzone
                  w={{ base: "170px" }}
                  me="50px"
                  maxH={{ base: "110px" }}
                  minH={{ base: "110px" }}
                  acceptedFormats={"image/png, image/jpeg"}
                  onFinishSelected={handleFinishSelectedImage}
                  content={
                    <Card
                      style={{
                        width: "160px",
                        height: "100px",
                        paddingTop: "30px",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "transparent",
                      }}
                    >
                      <Icon
                        as={MdUpload}
                        w="40px"
                        h="40px"
                        color={brandColor}
                      />

                      <Text fontSize="13px" fontWeight="700" color={brandColor}>
                        Upload Files Image
                      </Text>

                      <Text
                        style={{ width: "160px", textAlign: "center" }}
                        fontSize="sm"
                        fontWeight="500"
                        color="secondaryGray.500"
                      >
                        Png, Jpg files are allowed
                      </Text>
                    </Card>
                  }
                />
              </FormControl>
              {formType === "video" && (
                <FormControl>
                  <Dropzone
                    w={{ base: "170px" }}
                    me="100px"
                    maxH={{ base: "110px" }}
                    minH={{ base: "110px" }}
                    acceptedFormats={"video/mp4"}
                    onFinishSelected={handleFinishSelectedVideo}
                    content={
                      <Card
                        style={{
                          width: "160px",
                          height: "100px",
                          paddingTop: "30px",
                          alignItems: "center",
                          backgroundColor: "transparent",
                          justifyContent: "center",
                        }}
                      >
                        <Icon
                          as={MdUpload}
                          w="40px"
                          h="40px"
                          color={brandColor}
                        />

                        <Text
                          fontSize="13px"
                          fontWeight="700"
                          color={brandColor}
                        >
                          Upload Files Video
                        </Text>

                        <Text
                          style={{ width: "160px", textAlign: "center" }}
                          fontSize="sm"
                          fontWeight="500"
                          color="secondaryGray.500"
                        >
                          Mp4 files are allowed
                        </Text>
                      </Card>
                    }
                  />
                </FormControl>
              )}

              {formType === "object" && (
                <FormControl>
                  <Dropzone
                    w={{ base: "170px" }}
                    me="100px"
                    maxH={{ base: "110px" }}
                    minH={{ base: "110px" }}
                    acceptedFormats={".usdz"}
                    onFinishSelected={handleFinishSelectedObject}
                    content={
                      <Card
                        style={{
                          width: "160px",
                          height: "100px",
                          paddingTop: "30px",
                          backgroundColor: "transparent",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Icon
                          as={MdUpload}
                          w="40px"
                          h="40px"
                          color={brandColor}
                        />

                        <Text
                          fontSize="13px"
                          fontWeight="700"
                          color={brandColor}
                        >
                          Upload Files 3D Object
                        </Text>

                        <Text
                          style={{ width: "160px", textAlign: "center" }}
                          fontSize="sm"
                          fontWeight="500"
                          color="secondaryGray.500"
                        >
                          USDZ files are allowed
                        </Text>
                      </Card>
                    }
                  />
                </FormControl>
              )}
              {formType === "ytb" && (
                <FormControl>
                  <FormLabel>Link YouTube</FormLabel>
                  <Input
                    type="text"
                    name="ytb"
                    value={formData.ytb}
                    onChange={handleChange}
                  />
                </FormControl>
                )}
              {formType === "card" && (
                <FormControl>
                  <Dropzone
                    w={{ base: "170px" }}
                    me="100px"
                    maxH={{ base: "110px" }}
                    minH={{ base: "110px" }}
                    acceptedFormats={".png"}
                    onFinishSelected={handleFinishSelectedImageAvatar}
                    content={
                      <Card
                        style={{
                          width: "160px",
                          height: "100px",
                          paddingTop: "30px",
                          backgroundColor: "transparent",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Icon
                          as={MdUpload}
                          w="40px"
                          h="40px"
                          color={brandColor}
                        />

                        <Text
                          fontSize="13px"
                          fontWeight="700"
                          color={brandColor}
                        >
                          Upload Files Avatar Object
                        </Text>

                        <Text
                          style={{ width: "160px", textAlign: "center" }}
                          fontSize="sm"
                          fontWeight="500"
                          color="secondaryGray.500"
                        >
                          PNG files are allowed
                        </Text>
                      </Card>
                    }
                  />
                </FormControl>
              )}
            </InputGroup>
            {formType === "map" && (
              <InputGroup mt={4}>
                <FormControl mr={2}>
                  <FormLabel htmlFor="latitude">Latitude</FormLabel>
                  <Input
                    type="text"
                    id="mapLa"
                    value={formData.mapLa}
                    name="mapLa"
                    placeholder="Latitude"
                    color="gray.500"
                    onChange={handleChange}
                    required
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="longitude">Longitude</FormLabel>
                  <Input
                    type="text"
                    id="mapLo"
                    name="mapLo"
                    placeholder="Longitude"
                    value={formData.mapLo}

                    color="gray.500"
                    onChange={handleChange}
                    required
                  />
                </FormControl>
              </InputGroup>
            )}
            {formType === "card" && (
              <>
                <FormControl>
                  <FormLabel>Full Name</FormLabel>
                  <Input
                    type="text"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Address Site</FormLabel>
                  <InputGroup size="sm">
                    <InputLeftAddon>https://</InputLeftAddon>
                    <Input
                      type="text"
                      name="addressSite"
                      value={formData.addressSite}
                      onChange={handleChange}
                    />
                    <InputRightAddon>.com</InputRightAddon>
                  </InputGroup>
                </FormControl>

                <FormControl>
                  <FormLabel>Instagram</FormLabel>
                  <Input
                   type='tel' 
                    name="instagram"
                    value={formData.instagram}
                    onChange={handleChange}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Facebook</FormLabel>
                  <Input
                    type="text"
                    name="facebook"
                    value={formData.facebook}
                    onChange={handleChange}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Twitter</FormLabel>
                  <Input
                    type="text"
                    name="twitter"
                    value={formData.twitter}
                    onChange={handleChange}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Email</FormLabel>
                  <Input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Phone</FormLabel>
                  <Input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Description</FormLabel>
                  <Textarea
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Latitude</FormLabel>
                  <Input
                    type="text"
                    name="latitude"
                    value={formData.latitude}
                    onChange={handleChange}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Longitude</FormLabel>
                  <Input
                    type="text"
                    name="longitude"
                    value={formData.longitude}
                    onChange={handleChange}
                  />
                </FormControl>
              </>
            )}

            <FormControl mt={4}>
              <Checkbox
                id="isPassword"
                isChecked={formData.agreeTerms}
                onChange={handleAgreeTermsChange}
              >
                This Model need to Login.
              </Checkbox>
            </FormControl>

            <Button
  mt={6}
  
  type="submit"
  colorScheme={brandColor}
  style={{ backgroundColor: brandColor, width: 100, position: "relative" }}
  _hover={{ bg: brandColor }}
  _active={{ bg: brandColor }}
  disabled={isLoading}
  onClick={handleSubmit} // Assuming you have an onClick handler for the button
  
>
  {isLoading ? (
    <Spinner
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
      color="teal.500"
      size="sm"
    />
  ) : (
    "Submit"
  )}
</Button>
          </form>
        </ModalBody>
        <ModalFooter>
          {/* Additional content or buttons can be added here */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
export default MyFormEdit;
